import service from '@/utils/service';
import { dictToURI } from '@/utils/url';


export function searchSpecies(q) {
  return service({
    method: 'get',
    url: '/v1/species/?q=' + encodeURIComponent(q)
  });
}


export function getSpeciesDetail(slug) {
  return service({
    method: 'get',
    url: '/v1/species/' + slug + '/'
  });
}

export function create(data) {
  return service({
    method: 'post',
    url: '/v1/species/',
    data
  });
}

export function destroy(slug) {
  return service({
    method: 'delete',
    url: '/v1/species/' + slug + '/'
  });
}

export function list(queryParams) {
  return service({
    method: 'get',
    url: '/v1/species/?' + dictToURI(queryParams)
  });
}

export function update(slug, data) {
  return service({
    method: 'patch',
    url: `/v1/species/${slug}/`,
    data
  });
}