<template>
  <div class="footer">
    <p>
        版权所有©国家医学媒介生物监测重点实验室（广东）
    </p>
    <p>
      <a href="https://beian.miit.gov.cn/" target="_blank">
        备案号： 浙ICP备2020033205号
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.footer {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  box-sizing: border-box;
  padding: 20px 0;
  p {
    margin: 0;
  }
  p:nth-child(2) {
    margin-left: 28px;
    display: flex;
    align-items: center;
  }

  img {
    width: 25px;
    height: 26px;
    vertical-align: middle;
    margin-right: 3px;
  }
  a {
    text-decoration: none;
  }
}
</style>
