<template>
  <div class="home">
    <div class="home-header">
      <div class="con-header">
        <div class="con-logo">
          <span>病媒生物数字化标本库</span>
        </div>
        <div class="con-menus">
          <div class="menu" v-for="(menu, index) in menusList" :key="index" @click="selectMenu(menu)">
            <span>{{ menu.title }}</span>
            <div class="menu-line" v-show="menu.title === activeMenu"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-banner">
      <div class="con-text">
        <div class="title">病媒生物数字化标本库</div>
        <a-divider style="color: #fff; font-size: 20px;">Vector Species Library</a-divider>
        <div class="con-search" ref="search">

          <a-input v-model="value" placeholder="请输入物种名称、拉丁名、异名" @input="result">
            <a-icon slot="prefix" type="search" />
          </a-input>
          <div class="result-list" v-show="resultShow">
            <div class="result-item"
              v-for="species in resultList"
              :key="species.slug"
              @click="selectItem(species)"
            >
              <span>{{ species.name }}（<span v-html="species.sciname"></span>）</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block gray area">
      <div class="con-context">
        <div class="con-mutiple">
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s1.jpg" />
            </div>
            <p>缓慢细蚤</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s2.jpg" />
            </div>
            <p>孟蝇属</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s8.jpg" />
            </div>
            <p>绿蝇属</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s3.jpg" />
            </div>
            <p>广额金蝇</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s4.jpg" />
            </div>
            <p>大头金蝇</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s5.jpg" />
            </div>
            <p>凹圆孟蝇</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s6.jpg" />
            </div>
            <p>美洲大蠊</p>
          </div>
          <div class="con-img">
            <div class="div-img">
              <img src="../../assets/images/index/s7.jpg" />
            </div>
            <p>德国小蠊</p>
          </div>
        </div>
        <div class="con-call">
          <a href="/tree" class="link-btn">
            <a-button type="danger" size="large" class="button">
              浏览所有物种
            </a-button>
          </a>
        </div>
      </div>
    </div>
    <div class="block good">
      <div class="con-context">
        <div class="block-title">功能特色</div>
        <div class="con-mutiple">
          <div class="con-img">
            <p class="good-title">物种高清图</p>
            <div class="div-img">
              <img src="../../assets/images/index/g1.jpg" />
            </div>
            <p class="good-content">物种高清图</p>
            <p>
              <a href="/tree" class="link-btn">
                <a-button class="consult">立即查看</a-button>
              </a>
            </p>
          </div>
          <div class="con-img">
            <p class="good-title">物种序列</p>
            <div class="div-img">
              <img src="../../assets/images/index/g2.jpg" />
            </div>
            <p class="good-content">物种序列</p>
            <p>
              <a href="/tree" class="link-btn">
                <a-button class="consult">立即查看</a-button>
              </a>
            </p>
          </div>
          <div class="con-img">
            <p class="good-title">序列相似性搜索</p>
            <div class="div-img">
              <img src="../../assets/images/index/g3.jpg" />
            </div>
            <p class="good-content">序列相似性搜索</p>
            <p>
              <a href="/align" class="link-btn">
                <a-button class="consult">立即查看</a-button>
              </a>
            </p>
          </div>
          <div class="con-img">
            <p class="good-title">物种分布、采集地图</p>
            <div class="div-img">
              <img src="../../assets/images/index/g4.jpg" />
            </div>
            <p class="good-content">物种分布、采集地图</p>
            <p>
              <a href="/distribution" class="link-btn">
                <a-button class="consult">立即查看</a-button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { list } from '@/services/species';
import Footer from '@/components/Footer';

export default {
  name: 'home',
  components: {
    Footer
  },
  data() {
    return {
      menusList: [
        {
          title: '物种树',
          url: '/tree'
        },
        {
          title: '分布地',
          url: '/distribution'
        },
        {
          title: '采集地',
          url: '/collection'
        },
        {
          title: '检索',
          url: '/search'
        },
        {
          title: '比对',
          url: '/align'
        }
      ],
      activeMenu: '',
      value: '',
      resultList: [],
      resultShow: false
    }
  },
  created() {
    let that = this;
    document.addEventListener('click',function(e){
      let target = e.target;
      let isHave = that.$refs.search.contains(target)
      if(!isHave)that.resultShow=false
    })
  },
  methods: {
    selectMenu(menu) {
      this.$router.push(menu.url);
    },
    onSearch() {
      this.onSearch()
    },
    async result() {
      this.resultList = [];
      let params = {
        q: this.value
      };
      let response = await list(params);
      this.resultList = response.data.data.results;
      this.resultShow = this.resultList.length > 0
    },
    selectItem(species) {
      this.$router.push('/species/' + species.slug);
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  font-size: 14px;
  color: #333;
  .home-header {
    width: 100%;
    height: 64px;

    background: #6799ce;
    color: #fff;
    .con-header {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .con-logo {
        margin-right: 100px;
        img {
          width: 46px;
          height: 41px;
          margin-right: 16px;
          vertical-align: middle;
        }
        span {
          font-size: 25px;
          margin-left: 20px;
        }
      }
      .con-menus {
        display: flex;
        align-items: center;
        height: 100%;
        .menu {
          height: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          cursor: pointer;
          position: relative;
          line-height: 64px;
          .menu-line {
            width: 80%;
            height: 3px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 10%;
          }
        }
        .menu:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
  .home-banner {
    width: 100%;
    height: 360px;
    background: pink;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/index/banner.jpg') no-repeat center;
    background-size: cover;
    .con-text {
      width: 500px;
      color: #fff;
      .title {
        text-align: center;
        font-size: 50px;
      }
      .con-search {
        position: relative;
        .result-list {
          width: 100%;
          height: 200px;
          overflow-y: auto;
          position: absolute;
          top: 32px;
          left: 0;
          z-index: 100;
          background: #fff;
          box-shadow: 3px 3px 5px #666;
          .result-item {
            width: 100%;
            height: 36px;
            box-sizing: border-box;
            padding: 10px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;
          }
          .result-item:hover {
            background: #C6E2FF;
          }
        }
      }
    }
  }
  .con-call {

    text-align: center;
    margin-top: 20px;
    .button {
      margin: 20px 0;
    }
    .call {
      color: #333;
      font-size: 16px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
  .link-btn {
    display: inline-block;
  }
  .block {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 0;
    .con-context {
      width: 1200px;
      margin: 0 auto;
      .block-title {
        font-size: 32px;
        color: #333;
        text-align: center;
        margin-bottom: 50px;
      }
      .block-sub-title {
        width: 400px;
        margin: 0 auto;
      }
      .con-mutiple {
        display: flex;
        flex-wrap: wrap;
        .con-img {
          width: 25%;
          flex-shrink: 0;
          flex-grow: 0;
          .div-img {
            box-sizing: border-box;
            margin: 0 auto;
            margin-bottom: 10px;
            a {
              display: block;
            }
            img {
              display: block;
            }
          }
          p {
            width: 260px;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
      .con-desc {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        border: 1px solid #e8e8e8;
        margin: 0 auto;
        margin-top: 40px;
      }
    }
  }
  .area {
    .con-context {
      .con-mutiple {
        .con-img:nth-of-type(n+5) {
          margin-top: 20px;
        }
        .con-img {
          .div-img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            img {
              width: 200px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .good {
    .con-context {

      .con-mutiple {
        .con-img {
          .div-img {
            width: 260px;
            // margin: 0 auto;
            img {
              width: 260px;
              border-radius: 0;
            }
          }
          p {
            text-align: left;
            .consult {
              color: #ff4d4f;
              border-color: #ff4d4f;
              margin-top: 10px;
            }
          }
          p:first-child{
            margin-bottom: 10px;
          }
          .good-title {
            // font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .service {
    .con-context {

      .con-mutiple {
        .con-img {
          width: calc(100% / 3);
          .div-img {
            width: 300px;
            height: 300px;
            img {
              border-radius: 0;
            }
          }
          .block-panel {
            width: 260px;
            background: #fff;
            margin: 0 auto;
            border-radius: 5px;
            box-shadow: 3px 3px 5px #666;
            margin-top: -50px;
            position: relative;
            padding: 10px;
            .panel-title {
              font-weight: bold;
              text-align: center;
            }
            .panel-content {
              text-align: left;
              margin: 10px 0;
            }
          }
          p {
            width: 100%;
            .consult {
              color: #ff4d4f;
              border-color: #ff4d4f;
              margin-top: 10px;
            }
          }

        }
      }
    }
  }
}
</style>
